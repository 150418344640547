import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/scss/styles.scss";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import ToastCloseBtn from "@/components/ui/ToastCloseBtn.vue";
import VueTippy from "vue-tippy";
import VueGtag from "vue-gtag-next";
import AOS from "aos";
import "aos/dist/aos.css";
import * as Sentry from "@sentry/vue";


const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  timeout: 5000,
  hideProgressBar: true,
  draggable: false,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  icon: false,
  toastClassName: "custom-toast",
  closeButton: ToastCloseBtn,
  transition: "Vue-Toastification__fade",
  onMounted: (_, toastApp) => {
    toastApp.use(router);
  },
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      return false;
    }
    return toast;
  },
};

const app = createApp(App);

if (process.env.VUE_APP_GA_MEASUREMENT_ID) {
  app.use(VueGtag, {
    property: {
      id: process.env.VUE_APP_GA_MEASUREMENT_ID,
    },
  });
}

if (
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_CLARITY_PROJECT_ID
) {
  const allowedHosts = ["staging.lottrackr.com", "www.lottrackr.com.au"];
  const currentHost = window.location.hostname;
  if (
    allowedHosts.includes(currentHost) &&
    window.location.protocol === "https:"
  ) {
    const clarityScript = document.createElement("script");
    clarityScript.type = "text/javascript";
    clarityScript.async = true;
    clarityScript.src =
      "https://www.clarity.ms/tag/" + process.env.VUE_APP_CLARITY_PROJECT_ID;

    document.head.appendChild(clarityScript);
  }
}

if (process.env.NODE_ENV === "production") {
  Sentry.init({
      app,
      dsn: "https://9d272f7ac801a0b1ff74c527de900ce8@o4507297625145344.ingest.us.sentry.io/4507628782878720",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      process.env.VUE_APP_API_URL,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  console.log("Sentry is enabled");
};

app.use(store).use(router).use(Toast, options).use(VueTippy).mount("#app");
AOS.init({ startEvent: "load", once: true });
